import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

// auth provider
import { JWTProvider as AuthProvider } from './contexts/JWTContext';

import routes, { renderRoutes } from './routes';
import { LicenseInfo } from '@mui/x-license';
import { DATABIT } from './config/constant';
import { Encode64 } from './utils/crypto';
import { checkFileExists } from './utils/file';

const App = () => {
  useEffect(() => {
    const configUrl = `${process.env.PUBLIC_URL}/config.json`;
    checkFileExists(configUrl).then((exists) => {
      if (exists) {
        fetch(configUrl)
          .then((response) => response.json())
          .then((data) => {
            localStorage.setItem('urlconnect', Encode64(data.ulrconnect));
            LicenseInfo.setLicenseKey(data.licensedatagrid);
            localStorage.setItem('apikey_maps', Encode64(data.apikey_maps));
            localStorage.setItem('client_token_whats', Encode64(data.client_token_whats));
            localStorage.setItem('licensedatagrid', Encode64(data.licensedatagrid));
            sessionStorage.setItem('system', data.system);
            sessionStorage.setItem('ambiente', data.ambiente);
            DATABIT.islogged = data.islogged;
          })
          .catch((error) => {
            console.error('Erro ao carregar o arquivo JSON:', error);
          });
      } else {
        console.log('O arquivo não existe.');
      }
    });

    const handleBeforeUnload = (event) => {
      alert('Você está saindo da página!');
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <React.Fragment>
      {localStorage.getItem('urlconnect') !== '' && localStorage.getItem('urlconnect') !== undefined ? (
        <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
          <AuthProvider>{renderRoutes(routes)}</AuthProvider>
        </BrowserRouter>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default App;
