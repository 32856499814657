import { DATABIT } from '../../config/constant';
import axios from 'axios';
import { Decode64 } from '../../utils/crypto';

const getConnect = () => {
  console.log(sessionStorage.getItem('ambiente'));
  if (parseInt(sessionStorage.getItem('ambiente')) === 2) {
    return 'http://localhost:8879/restConnect.dll/datasnap/rest/tmetodos/';
  } else {
    return 'http://databitbh.com:38879/restConnect.dll/datasnap/rest/tmetodos/';
  }
};

const connectInstance = axios.create({
  baseURL: getConnect(),
  timeout: 30000,
  headers: {
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*'
  }
});

export default connectInstance;
